<template>
    <div style="text-align:center;">
        <h4>Donate</h4>
        <a @click="btcCopy"><font-awesome-icon class="icons" :icon="['fab', 'btc']" /></a>
        <a @click="xmrCopy"><font-awesome-icon class="icons" :icon="['fab', 'monero']" /></a>
        <a :href="coffee"><font-awesome-icon class="icons" :icon="['fa', 'coffee']" /> </a>
    </div>
</template>

<script>
import { copyText } from 'vue3-clipboard'

export default {
  name: 'App',
  data(){
      return {
        btc: 'bc1q98c8pc7hk85cljhsgj6gdd4sd98zcduqx3x8z4',
        xmr: '47teg4XgBCWHx6uNAaXiob3Bywa4ifefoWBogpnRiWDgSKcVs18EVDQAmZKti4noWNgP1zQ7YaqL8BvPm9u5iyKtTqs6ENH',
        coffee: "https://www.buymeacoffee.com/B3p0"
    }
  },
  setup() {
      const btcCopy = () => {
        copyText('bc1q98c8pc7hk85cljhsgj6gdd4sd98zcduqx3x8z4', undefined, (error, event) => {
          if (error) {
            alert('Can not copy')
            console.log(error)
          } else {
            alert('Copied btc wallet: bc1q98c8pc7hk85cljhsgj6gdd4sd98zcduqx3x8z4')
            console.log(event)
          }
        })
      };
      const xmrCopy = () => {
        copyText('47teg4XgBCWHx6uNAaXiob3Bywa4ifefoWBogpnRiWDgSKcVs18EVDQAmZKti4noWNgP1zQ7YaqL8BvPm9u5iyKtTqs6ENH', undefined, (error, event) => {
          if (error) {
            alert('Can not copy')
            console.log(error)
          } else {
            alert('Copied xmr wallet: 47teg4XgBCWHx6uNAaXiob3Bywa4ifefoWBogpnRiWDgSKcVs18EVDQAmZKti4noWNgP1zQ7YaqL8BvPm9u5iyKtTqs6ENH')
            console.log(event)
          }
        })
      };
      return { btcCopy, xmrCopy };
    },
}
</script>

<style scoped>
    a {
        text-decoration: none;
        color: #2c3e50;
    }
    h4 {
        margin: 0px;
        font-weight: bold;
        font-style: italic;
    }
    .icons:hover {
      cursor: pointer;
    }
    
</style>