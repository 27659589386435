<template>
    <div style="text-align:center;">
        <h4>Contact</h4>
        <a :href="'mailto:' + email"><font-awesome-icon class="icons" :icon="['fa', 'envelope']" /> </a>
        <a :href="gitlab"><font-awesome-icon class="icons" :icon="['fab', 'gitlab']" /> </a>
        <a :href="twitter"><font-awesome-icon class="icons" :icon="['fab', 'twitter-square']" /> </a>
    </div>
</template>
<script>
export default {
  name: 'App',
  data(){
      return {
      email: "me@b3po.io",
      twitter: "https://twitter.com/B3POio",
      gitlab: "https://gitlab.com/B3PO"
  }
  } 
}
</script>

<style scoped>
    a {
        text-decoration: none;
        color: #2c3e50;
    }

    h4 {
        margin: 0px;
    }
    
</style>