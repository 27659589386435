<template>
    <div style="text-align:center;">
            <p>
                <VueWriter :array="arr" :eraseSpeed="20" :typespeed="10" :iterations="2" /> 
            </p>
            <p>
                $ git clone https://gitlab.com/B3PO/B3PO<br>
                $ cd B3PO<br>
                $ npm i -g vue@latest @vue/cli<br>
                $ npm install && npm run serve
            </p>
    </div>
</template>

<script>
  export default {
    data() {
      return { 
        arr: [
              "My name is B3PO, human-cyborg relations",  
              "Web App Developer - Vue, Node, Express, MongoDB",
              "Security Researcher - Software, Servers, Networks",
              "Contact below for consultation or development",
            ] 
        };
    },
  };
</script>


<style scoped>
    p { 
        background-color: #2c3e50; 
        color: #FFF; 
        text-align: left; 
        font-size: 16px;
        font-weight: bold;
        overflow: hidden;
        margin: 0 auto 15px auto;
        border-radius: 25px;
        max-width: 550px;
        padding: 10px;
    }

    @media only screen and (max-width: 1000px) {
        p { 
            max-width: 550px;
            max-height: 110px;
            margin: 10px auto 10px auto;
        }
    }

    @media only screen and (max-width: 670px) {
        p { 
            max-width: 98%;
            margin: 10px 5px 10px 5px;
            font-size: 12px;
        }
    }
</style>