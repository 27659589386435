import { createApp } from 'vue'
import App from './App.vue'
import { library } from "@fortawesome/fontawesome-svg-core";
import { faEnvelope, faCoffee } from "@fortawesome/free-solid-svg-icons";
import { faVuejs, faAngular, faAws, faHtml5, faBootstrap, faBots, faBtc,  faCss3Alt, faDigitalOcean, faDocker, faEthereum,  faGitAlt, faGithub,  faGitlab, faGolang, faJediOrder, faJsSquare, faLinux, faUbuntu, faMonero, faNode, faNodeJs, faNpm, faPython, faRaspberryPi, faTwitterSquare } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import VueWriter from "vue-writer";
import BootstrapVue3 from 'bootstrap-vue-3'
import VueClipboard from 'vue3-clipboard'

library.add( faEnvelope, faCoffee, faVuejs, faAngular, faAws, faHtml5, faBootstrap, faBots,faBtc,  faCss3Alt, faDigitalOcean, faDocker, faEthereum, faGitAlt, faGithub,  faGitlab, faGolang, faJediOrder,  faJsSquare, faLinux, faUbuntu, faMonero, faNode, faNodeJs,faNpm, faPython, faRaspberryPi, faTwitterSquare);

createApp(App)
    .use(VueWriter)
    .use(BootstrapVue3)
    .use(VueClipboard, {
        autoSetContainer: true,
        appendToBody: true,
      })
    .component("font-awesome-icon", FontAwesomeIcon)
    .mount('#app')
