<template>
    <div class="row">
      <div class="column">
        <ContactUs />
      </div>
      <div class="column">
        <Donate />
      </div>
  </div>
  <blockquote>
           "Simplicity is the ultimate sophistication."
           <br> ~ Leonardo da Vinci
  </blockquote>
</template>

<script>
import ContactUs from './ContactUs.vue'
import Donate from './Donate.vue'

export default {
  name: 'FooterBar',
  components: {
    ContactUs,
    Donate
  }
}
</script>

<style scoped>
* {
  box-sizing: border-box;
}

.column {
  float: left;
  width: 50%;
  padding: 10px;
}

.row:after {
  content: "";
  display: table;
  clear: both;
  max-width: 550px;
}

blockquote {
        font-family: monaco;
        font-style: italic;
        font-size: 18px;
}

@media only screen and (max-width: 670px) {
    blockquote {
        font-size: 11px;
        font-weight: bold;
    }
}
</style>

