<template>
  <h2 class="animate-flicker">Hello World</h2>
  <font-awesome-icon class="icons" :icon="['fab', 'btc']" />   
  <font-awesome-icon class="icons" :icon="['fab', 'monero']" />
  <font-awesome-icon class="icons" :icon="['fab', 'jedi-order']" />   
  <font-awesome-icon class="icons" :icon="['fab', 'raspberry-pi']" />  
  <font-awesome-icon class="icons" :icon="['fab', 'bots']" /> 
  <br>
  <font-awesome-icon class="icons" :icon="['fab', 'html5']" />  
  <font-awesome-icon class="icons" :icon="['fab', 'css3-alt']" />  
  <font-awesome-icon class="icons" :icon="['fab', 'bootstrap']" />
  <font-awesome-icon class="icons" :icon="['fab', 'js-square']" />  
  <font-awesome-icon class="icons" :icon="['fab', 'vuejs']" />   
  <!--<font-awesome-icon class="icons" :icon="['fab', 'angular']" /> --> 
  <br>
  <font-awesome-icon class="icons" :icon="['fab', 'node']" />   
  <font-awesome-icon class="icons" :icon="['fab', 'node-js']" />   
  <font-awesome-icon class="icons" :icon="['fab', 'npm']" />  
  <font-awesome-icon class="icons" :icon="['fab', 'python']" />  
  <font-awesome-icon class="icons" :icon="['fab', 'golang']" />  
  <!--<font-awesome-icon class="icons" :icon="['fab', 'ethereum']" />-->
  <br>
  <font-awesome-icon class="icons" :icon="['fab', 'digital-ocean']" />  
  <font-awesome-icon class="icons" :icon="['fab', 'linux']" />  
  <font-awesome-icon class="icons" :icon="['fab', 'ubuntu']" /> 
  <!--<font-awesome-icon class="icons" :icon="['fab', 'git-alt']" />   
  <font-awesome-icon class="icons" :icon="['fab', 'github']" />  -->
  <font-awesome-icon class="icons" :icon="['fab', 'gitlab']" />  
  <font-awesome-icon class="icons" :icon="['fab', 'docker']" /> 
  <br>  
  
  
</template>
        
<style> 
  @keyframes flickerAnimation {
    0%   { opacity:1; }
    50%  { opacity:0; }
    100% { opacity:1; }
  }
  @-o-keyframes flickerAnimation{
    0%   { opacity:1; }
    50%  { opacity:0; }
    100% { opacity:1; }
  }
  @-moz-keyframes flickerAnimation{
    0%   { opacity:1; }
    50%  { opacity:0; }
    100% { opacity:1; }
  }
  @-webkit-keyframes flickerAnimation{
    0%   { opacity:1; }
    50%  { opacity:0; }
    100% { opacity:1; }
  }
  .animate-flicker {
    -webkit-animation: flickerAnimation 2.5s infinite;
    -moz-animation: flickerAnimation 2.5s infinite;
    -o-animation: flickerAnimation 2.5s infinite;
      animation: flickerAnimation 2.5s infinite;
  }
  h2 {
    font-size: 48px;
    margin: 1%;
  }
  .icons {
    height: 50px;
    width: 50px;
    margin: 13px;
  }

  @media only screen and (max-width: 670px) {
        h4 {
          text-align: center;
          margin: 5px;
        }
        .icons {
          height: 35px;
          width: 35px;
          margin: 5px;
        }
  }
</style>
