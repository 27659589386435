<template>
  <HelloWorld msg="Hello World"/>
  <WelcomeHere />
  <CodeEdit />
  <div style="max-width:550px; margin: auto;">
    <FooterBar  />
  </div>
</template>

<script>
import HelloWorld from './components/HelloWorld.vue'
import WelcomeHere from './components/WelcomeHere.vue'
import CodeEdit from './components/CodeEdit.vue'
import FooterBar from './components/Footer.vue'

export default {
  name: 'App',
  components: {
    HelloWorld,
    WelcomeHere,
    CodeEdit,
    FooterBar
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
body {
  margin: 0px;
}

</style>
